@import 'themes-vars.module.scss';

:root {
    --toastify-color-info: #3bafda;
    --toastify-color-error: #eb1717;
    --toastify-color-success: #8cc152;
}

.plandid-avatar-container {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin: 8px 0 8px 8px;
    cursor: pointer;
}

.secondary-main-tint {
    filter: invert(25%) sepia(73%) saturate(1569%) hue-rotate(241deg) brightness(93%) contrast(98%);
}

.action-table-header {
    text-align: center;
}

.serial-no-data-col {
    width: 100px;
    text-align: left !important;
}

.action-view-btn {
    height: 30px;
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid $primary200;
}
.ignore {
    svg {
        color: #2196f3 !important;
    }
}
